import { BUSINESS_FORM_LINKS, USER_FORM_LINKS } from '@/constants/forms';
import { useBusinessForm, usePersonalForm } from '../hooks';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { MdDelete } from 'react-icons/md';
import toast from 'react-hot-toast';

export enum EFormType {
  Personal = 'personal',
  Business = 'business',
}

interface FormPersonalProps {
  form_type: EFormType;
}

export const CustomForm = ({ form_type }: FormPersonalProps) => {
  const { state } = useLocation();

  const {
    createSurvey,
    surveySlugHook,
    getSurveys,
    deleteQuestion,
    createOption,
    updateQuestion,
  } =
    form_type === EFormType.Personal
      ? usePersonalForm(state.companyId)
      : useBusinessForm(state.companyId);

  let defaultLinkTo = '';

  if (form_type === EFormType.Personal) {
    defaultLinkTo = USER_FORM_LINKS[USER_FORM_LINKS.length - 1].value;
  } else if (form_type === EFormType.Business) {
    defaultLinkTo = BUSINESS_FORM_LINKS[BUSINESS_FORM_LINKS.length - 1].value;
  }

  const optionVoid: Option = {
    isEditing: false,
    data: {
      title: '',
      type: 'text',
      isRequired: false,
      linkTo: defaultLinkTo,
      negativeNumbers: false,
    },
  };

  const [options, setOptions] = useState<Option[]>([optionVoid]);

  const [error, setError] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  const [loadingComponentList, setLoadingComponentList] =
    useState<boolean>(false);

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = async () => {
    try {
      if (!state.companyId) return;
      setLoading(true);

      const options: DataForm[] = await getSurveys(state.companyId);

      let createOptions: Option[] = [];

      if (options.length > 0) {
        options.forEach((option) => {
          createOptions.push({
            id: option.id ?? 0,
            isEditing: true,
            data: option,
          });
        });
      }

      if (createOptions.length > 0) {
        setOptions(createOptions);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteOption = async (optionToDelete: Option) => {
    if (!optionToDelete.isEditing) {
      await deleteQuestion(String(optionToDelete.id));

      const optionsGet: DataForm[] = await getSurveys(state.companyId);

      let createOptions: Option[] = [];

      if (optionsGet.length > 0) {
        optionsGet.forEach((option) => {
          createOptions.push({
            id: option.id ?? 0,
            isEditing: true,
            data: option,
          });
        });
      }

      if (createOptions.length > 0) {
        setOptions(createOptions);
      }
      return;
    }
    try {
      setLoadingComponentList(true);
      await deleteQuestion(String(optionToDelete.id));

      const optionsGet: DataForm[] = await getSurveys(state.companyId);

      let createOptions: Option[] = [];

      if (optionsGet.length > 0) {
        optionsGet.forEach((option) => {
          createOptions.push({
            id: option.id ?? 0,
            isEditing: true,
            data: option,
          });
        });
      }

      if (createOptions.length > 0) {
        setOptions(createOptions);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingComponentList(false);
    }
  };

  const handleSaveForm = async () => {
    for (let option of options) {
      if (!option.data.title) {
        toast.error('Por favor, ingrese un titulo para el campo');
        return;
      }

      if (!option.data.linkTo) {
        toast.error('Por favor, seleccione una asociación para el campo');
        return;
      }
    }

    try {
      setLoadingComponentList(true);
      let surveySlug = '';
      if (surveySlugHook) {
        surveySlug = surveySlugHook;
      } else {
        const slug = await createSurvey();

        if (!slug) {
          return;
        }
        surveySlug = slug;
      }

      // iterar opciones
      for (let option of options) {
        // si la opcion esta en edicion, actualizar en backend
        if (option.isEditing) {
          // Tenemos un id de la opcion -> actualizarlo
          await updateQuestion(
            String(option.id),
            option.data.title,
            option.data.linkTo,
            {
              required: option.data.isRequired,
              allowNegativeNumbers: option.data.negativeNumbers,
              typeInput: option.data.type,
            }
          );
          continue;
        } else {
          if (option.data.title) {
            let validations = {
              required: option.data.isRequired,
              allowNegativeNumbers: option.data.negativeNumbers,
              typeInput: option.data.type,
            };
            // Crear en backend
            await createOption(
              surveySlug,
              option.data.title,
              option.data.linkTo,
              validations
            );
          }
        }
      }

      const optionsGet: DataForm[] = await getSurveys(state.companyId);

      let createOptions: Option[] = [];

      if (optionsGet.length > 0) {
        optionsGet.forEach((option) => {
          createOptions.push({
            id: option.id ?? 0,
            isEditing: true,
            data: option,
          });
        });
      }

      if (createOptions.length > 0) {
        setOptions(createOptions);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingComponentList(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center pt-20 pb-10">
        <Oval
          height="60"
          width="60"
          color="black"
          secondaryColor="#adb5bd"
          strokeWidth={5}
          strokeWidthSecondary={5}
        />
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="relative">
        {options.map((option, index) => {
          return (
            <Form
              option={option}
              deleteOption={deleteOption}
              key={option.id}
              options={options}
              setOptions={setOptions}
              optionNumber={index + 1}
              constsToUse={
                form_type === EFormType.Personal
                  ? USER_FORM_LINKS
                  : BUSINESS_FORM_LINKS
              }
            />
          );
        })}

        {loadingComponentList && (
          <div className="absolute top-0 left-0 h-full w-full bg-white rounded-md !opacity-80 flex justify-center items-center">
            <Oval
              height="60"
              width="60"
              color="black"
              secondaryColor="#adb5bd"
              strokeWidth={5}
              strokeWidthSecondary={5}
            />
          </div>
        )}
      </div>

      {error && <div className="mb-3 text-center text-red-500">{error}</div>}

      <button
        type="button"
        className="bg-black text-white h-[40px] px-3 rounded-md hover:bg-gray-500"
        onClick={() => {
          setOptions((opt) => {
            const optionsLength = opt.length;

            if (!opt[optionsLength - 1].data.title) {
              setError('Agrega un titulo');

              return opt;
            }

            if (!opt[optionsLength - 1].data.linkTo) {
              setError('Agrega una asociación');

              return opt;
            }

            setError('');

            return [
              ...opt,
              {
                id: crypto.randomUUID(),
                isEditing: false,
                data: {
                  title: '',
                  type: 'text',
                  isRequired: false,
                  negativeNumbers: false,
                  linkTo: '',
                },
              },
            ];
          });
        }}
      >
        Agregar otro campo
      </button>

      <button
        type="button"
        onClick={handleSaveForm}
        className="bg-green-500 text-black h-[40px] px-3 rounded-md hover:bg-gray-500 ml-3"
      >
        Guardar
      </button>
    </div>
  );
};

interface Option {
  id?: string | number;
  isEditing: boolean;
  data: DataForm;
}

interface FormOption {
  option: Option;
  deleteOption: (option: Option) => void;
  options: Option[];
  setOptions: React.Dispatch<React.SetStateAction<Option[]>>;
  optionNumber: number;
  constsToUse: any;
}

export interface DataForm {
  id?: number;
  title: string;
  type: string;
  isRequired: boolean;
  linkTo: string;
  negativeNumbers: boolean;
}

const Form = ({
  option,
  deleteOption,
  options,
  setOptions,
  optionNumber,
  constsToUse,
}: FormOption) => {
  const [currentForm, setCurrentForm] = useState<DataForm>(option.data);

  useEffect(() => {
    let optionsModified = [...options];
    optionsModified = optionsModified.map((optionMap) => {
      if (optionMap.id !== option.id) return optionMap;
      return {
        id: option.id,
        isEditing: option.isEditing,
        data: currentForm,
      };
    });

    setOptions(optionsModified);
  }, [currentForm]);

  const stylesTitles = 'w-fit my-1';

  const stylesInputs =
    'w-[300px] h-[40px] px-2 rounded-md bg-white border-black border-[1px] disabled:bg-gray-200';

  const stylesCheckbox = 'flex gap-2 items-center';

  return (
    <form
      key={option.id}
      className="w-full bg-gray-100 py-4 mb-5 rounded-xl mt-3 flex flex-col gap-2 justify-center items-center"
    >
      <span className="w-full px-3">Opción: {optionNumber}</span>

      <div className="mb-1">
        <h3 className={stylesTitles}>Ingrese el titulo del campo:</h3>

        <input
          type="text"
          value={currentForm.title}
          onChange={(event) =>
            setCurrentForm({
              ...currentForm,
              title: event.target.value,
            })
          }
          className={`${stylesInputs}`}
        />
      </div>

      <div>
        <h3 className={stylesTitles}>Seleccione el tipo de input:</h3>
        <select
          value={currentForm.type}
          onChange={(event) =>
            setCurrentForm({
              ...currentForm,
              type: event.target.value,
            })
          }
          className={stylesInputs}
        >
          <option value="text">Texto</option>
          <option value="number">Numerico</option>
        </select>
      </div>

      <div>
        <h3 className={stylesTitles}>Asociación al perfil:</h3>
        <select
          value={currentForm.linkTo}
          onChange={(event) =>
            setCurrentForm({
              ...currentForm,
              linkTo: event.target.value,
            })
          }
          className={stylesInputs}
        >
          <option value="" hidden>
            Seleccione
          </option>

          {constsToUse.map((link_option: any, index: number) => (
            <option key={index} value={link_option.value}>
              {link_option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="my-3">
        <label className={stylesCheckbox}>
          <input
            type="checkbox"
            checked={currentForm.isRequired}
            onChange={() =>
              setCurrentForm({
                ...currentForm,
                isRequired: !currentForm.isRequired,
              })
            }
          />

          <span>Es requerido?</span>
        </label>

        {currentForm.type === 'number' && (
          <label className={stylesCheckbox}>
            <input
              type="checkbox"
              checked={currentForm.negativeNumbers}
              onChange={() =>
                setCurrentForm({
                  ...currentForm,
                  negativeNumbers: !currentForm.negativeNumbers,
                })
              }
            />

            <span>Aceptar numeros negativos?</span>
          </label>
        )}
      </div>

      <div className="w-full flex justify-end px-3">
        <button
          type="button"
          onClick={() => deleteOption(option)}
          className="flex items-center gap-1 bg-red-500 text-white px-2 py-1 rounded-md"
        >
          Eliminar
          <MdDelete className="text-[24px]" />
        </button>
      </div>
    </form>
  );
};
