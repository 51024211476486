import useAxiosPrivate from "@/hooks/useApiV2Private"
import { SURVEY_STATUS_OPTIONS } from "@/pages/interactions/constants/status"
import { CreateSurveyQuestionResponse, CreateSurveyResponse } from "@/pages/interactions/interfaces/partSurvey.interface"
import { Survey } from "@/pages/interactions/interfaces/survey.interface"
import toast from "react-hot-toast"

export const usePartSurvey = (companySlug: string) => {

  const apiV2 = useAxiosPrivate()

  /**
   * The function `createSurvey` asynchronously creates a survey with a random title and active status
   * using an API call.
   * @returns The `createSurvey` function is returning a `Promise` that resolves to either a
   * `CreateSurveyResponse` object or `undefined`.
   */
  const createSurvey = async (titleSurvey: string): Promise<CreateSurveyResponse | undefined> => {
    try {
      const res = await apiV2.post(`/surveys?company_slug=${companySlug}`, {
        title: titleSurvey,
        status: SURVEY_STATUS_OPTIONS.ACTIVE
      })
      const data: CreateSurveyResponse = res.data
      return data
    }
    catch (err) {
      console.error('Error creando la encuesta', err)
      toast.error('Error creando la encuesta')
    }
  }

  /**
   * The function `createQuestion` asynchronously creates a survey question using data provided and
   * returns a response or logs an error.
   * @param {string} surveySlug - The `surveySlug` parameter is a string that represents the unique
   * identifier or slug of a survey. It is used to specify which survey the question should be
   * associated with when creating a new survey question.
   * @param dataQuestion - The `dataQuestion` parameter in the `createQuestion` function represents the
   * data that will be used to create a new survey question. It is of type `Record<string, unknown>`,
   * which means it is an object where the keys are strings and the values can be of any type. This
   * parameter
   * @returns The function `createQuestion` is returning a `Promise` that resolves to either a
   * `CreateSurveyQuestionResponse` object or `undefined`.
   */
  const createQuestion = async (surveySlug: string, dataQuestion: Record<string, unknown>): Promise<CreateSurveyQuestionResponse | undefined> => {
    try {
      const res = await apiV2.post(`/surveys/${surveySlug}/questions`, dataQuestion)
      const data: CreateSurveyQuestionResponse = res.data
      return data

    }
    catch (err) {
      console.error('Error creando la pregunta', err)
      toast.error('Error creando la pregunta')
    }
  }

  /**
   * The function `createOption` asynchronously creates an option for a specific question in a survey
   * using data provided and returns a boolean indicating success or failure.
   * @param {string} surveySlug - The `surveySlug` parameter is a string that represents the unique
   * identifier or slug of a survey. It is used to specify which survey the question and option belong
   * to when creating a new option for a question in a survey.
   * @param {number} questionId - The `questionId` parameter in the `createOption` function represents
   * the unique identifier of the question to which the option will be added. It is a number that helps
   * identify the specific question within a survey.
   * @param dataOption - The `dataOption` parameter in the `createOption` function represents the data
   * that will be used to create a new option for a question in a survey. This data typically includes
   * information such as the text of the option, any additional properties related to the option, or
   * any other relevant details needed to
   * @returns The `createOption` function returns a `Promise<boolean>`. If the option creation is
   * successful, it will return `true`. If there is an error during the creation process, it will catch
   * the error, log it to the console, display an error message using `toast.error`, and then return
   * `false`.
   */
  const createOption = async (surveySlug: string, questionId: number, dataOption: Record<string, unknown>): Promise<boolean> => {
    try {
      await apiV2.post(`/surveys/${surveySlug}/questions/${questionId}/options`, {
        ...dataOption,
      })
      return true
    }
    catch (err) {
      console.error('Error creando la pregunta', err)
      toast.error('Error creando la pregunta')
      return false
    }
  }

  /**
   * Updates an option for a specific question in a survey.
   *
   * @param {string} surveySlug - The slug identifier for the survey.
   * @param {number} questionId - The ID of the question to which the option belongs.
   * @param {number} optionId - The ID of the option to be updated.
   * @param {Record<string, unknown>} dataOption - The data to update the option with.
   * @returns {Promise<boolean>} - A promise that resolves to true if the update was successful, otherwise false.
   *
   * @throws {Error} - Throws an error if the update operation fails.
   */
  const updateOption = async (surveySlug: string, questionId: number, optionId: number, dataOption: Record<string, unknown>): Promise<boolean> => {
    try {
      await apiV2.patch(`/surveys/${surveySlug}/questions/${questionId}/options/${optionId}`, dataOption)
      return true
    }
    catch (err) {
      console.error('Error actualizando la opción', err)
      toast.error('Error actualizando la opción')
      return false
    }
  }

  /**
   * The function `getSurvey` asynchronously retrieves survey data based on a provided survey slug and
   * handles errors accordingly.
   * @param {string} surveySlug - The `surveySlug` parameter in the `getSurvey` function is a string
   * that represents the unique identifier or slug of a survey. This parameter is used to fetch the
   * survey data from the API endpoint `/surveys/`.
   * @returns The function `getSurvey` is returning the data fetched from the API endpoint
   * `/surveys/`.
   */
  const getSurvey = async (surveySlug: string): Promise<Survey | undefined> => {
    try {
      const res = await apiV2.get(`/surveys/${surveySlug}`)
      const data = res.data
      return data
    }
    catch(err) {
      console.error('Error obteniendo la encuesta', err)
      toast.error('Error obteniendo la encuesta')
    }
  }

 /**
  * The function `updateQuestion` updates a question in a survey using data provided and returns a
  * boolean indicating success or failure.
  * @param {string} surveySlug - The `surveySlug` parameter is a string that represents the unique
  * identifier or slug of a survey. It is used to specify which survey the question belongs to when
  * updating the question in the database.
  * @param {number} questionId - The `questionId` parameter in the `updateQuestion` function represents
  * the unique identifier of the question that you want to update within a survey. It is used to
  * specify which question should be updated when making a request to the API endpoint.
  * @param dataQuestion - The `dataQuestion` parameter in the `updateQuestion` function represents the
  * updated data for a specific question in a survey. It is of type `Record<string, unknown>`, which
  * means it is an object where the keys are strings and the values can be of any type. This parameter
  * is used
  * @returns The `updateQuestion` function returns a Promise that resolves to a boolean value. If the
  * update operation is successful, it returns `true`. If there is an error during the update
  * operation, it catches the error, logs it to the console, displays an error message using
  * `toast.error`, and then returns `false`.
  */
  const updateQuestion = async (surveySlug: string, questionId: number, dataQuestion: Record<string, unknown>): Promise<boolean> => {
    try {
      await apiV2.put(`/surveys/${surveySlug}/questions/${questionId}`, dataQuestion)
      return true
    }
    catch (err) {
      console.error('Error actualizando la pregunta', err)
      toast.error('Error actualizando la pregunta')
      return false
    }
  }

  /**
   * This TypeScript function `deleteOption` deletes a specific option for a question in a survey using
   * an API call and returns a boolean indicating success or failure.
   * @param {string} surveySlug - The `surveySlug` parameter is a string that represents the unique
   * identifier or slug of a survey. It is used to specify which survey the question and option belong
   * to when deleting an option from a question in a survey.
   * @param {number} questionId - The `questionId` parameter in the `deleteOption` function represents
   * the unique identifier of the question to which the option belongs. It is used to specify the
   * question under which the option needs to be deleted.
   * @param {number} optionId - The `optionId` parameter in the `deleteOption` function represents the
   * unique identifier of the option that you want to delete. This identifier is used to specify which
   * option within a question should be removed from the survey.
   * @returns The deleteOption function returns a Promise that resolves to a boolean value. If the
   * deletion operation is successful, it returns true. If there is an error during the deletion
   * process, it logs the error, displays an error message using toast, and returns false.
   */
  const deleteOption = async (surveySlug: string, questionId: number, optionId: number): Promise<boolean> => {
    try {
      await apiV2.delete(`/surveys/${surveySlug}/questions/${questionId}/options/${optionId}`)
      return true
    }
    catch (err) {
      console.error('Error eliminando la opción', err)
      toast.error('Error eliminando la opción')
      return false
    }
  }

  const updateSurvey = async (surveySlug: string, data: Survey) => {
    await apiV2.put(`/surveys/${surveySlug}`, data)
  }
  
  return {
    createSurvey,
    createQuestion,
    createOption,
    getSurvey,
    updateQuestion,
    deleteOption,
    updateSurvey,
    updateOption
  }
}